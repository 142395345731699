import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import { useAuthDialogStore } from '@cocoonspace/shared/domains/auth/stores/use-auth-dialog-store.hook'
import { Button } from '@cocoonspace/ui-admin/components/button'
import { Form } from '@cocoonspace/ui-admin/components/form'
import { FormControlledInput } from '@cocoonspace/ui-admin/components/form-controlled-input'
import { Input } from '@cocoonspace/ui-admin/components/input'
import { zodResolver } from '@hookform/resolvers/zod'
import { Trans, useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

type FormData = z.infer<typeof forgottenPassSchema>

const forgottenPassSchema = z.object({
	email: z
		.string()
		.min(1, { message: 'errors:forms.required' })
		.email({ message: 'errors:forms.invalidEmail' }),
})

export const ForgottenPassForm = () => {
	const { t } = useTranslation()
	const { sendPasswordResetEmail } = useAuth()
	const setActiveTab = useAuthDialogStore((state) => state.setActiveTab)

	const form = useForm<FormData>({
		resolver: zodResolver(forgottenPassSchema),
		values: {
			email: '',
		},
	})

	const onSubmitForm = (data: FormData) =>
		sendPasswordResetEmail(data.email)
			.then(() => {
				toast.success(
					<Trans
						parent='div'
						i18nKey={'actions:resetPassword.successMessage'}
						values={{ ...data }}
					/>,
				)
			})
			.catch(() => toast.error(t('errors:generic')))

	return (
		<>
			<Trans
				parent='p'
				i18nKey='auth:recover.body'
			/>

			<div className='flex flex-col gap-6'>
				<form
					noValidate
					className='flex flex-col gap-4'
					onSubmit={form.handleSubmit(onSubmitForm)}
				>
					<Form {...form}>
						<FormControlledInput
							name='email'
							label={t('attributesLabels:email')}
							control={form.control}
							render={({ field, fieldState }) => (
								<Input
									{...field}
									type='email'
									required
								/>
							)}
							required
						/>

						<Button
							type='submit'
							className='w-full'
						>
							{t('actions:resetPassword.btnLabel')}
						</Button>
					</Form>
				</form>

				<button
					type='button'
					className='text-primary'
					onClick={() => setActiveTab('login')}
				>
					{t('actions:login.btnLabel')}
				</button>
			</div>
		</>
	)
}
