import Axios, { type AxiosError, type AxiosRequestConfig } from 'axios'
import { stringify } from 'qs'

const baseURL = process.env.NEXT_PUBLIC_COCOON_API_V2_BASE_URL as string

export const AXIOS_INSTANCE = Axios.create({
	baseURL,
	headers: {
		'X-Api-Key': process.env.NEXT_PUBLIC_COCOON_API_V2_KEY as string,
	},
})

// add a second `options` argument here if you want to pass extra options to each generated query
export const useCustomInstance = <T>(
	config: AxiosRequestConfig,
	options?: AxiosRequestConfig,
): Promise<T> => {
	const source = Axios.CancelToken.source()

	const promise = AXIOS_INSTANCE({
		...config,
		...options,
		cancelToken: source.token,
		paramsSerializer: (params) =>
			stringify(params, { arrayFormat: 'repeat', allowDots: true }),
	}).then(({ data }) => data)

	// @ts-expect-error Par-ce que
	promise.cancel = () => {
		source.cancel('Query was cancelled')
	}

	return promise
}

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>
export type BodyType<BodyData> = BodyData
