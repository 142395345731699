import { useDebounce } from '@cocoonspace/shared/hooks/use-debounce.hook'
import { Badge } from '@cocoonspace/ui-admin/components/badge'
import {
	CommandDialog,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '@cocoonspace/ui-admin/components/command'
import { Separator } from '@cocoonspace/ui-admin/components/separator'
import { Trans, useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { DescribeIcon } from '~/components/describe-icon'
import { useEntriesList } from '~/hooks/admin-api/entry.hook'
import { searchCategories, useCommandKStore } from '~/stores/command-k.store'

const CategorySwitch = () => {
	const searchCategory = useCommandKStore.use.searchCategory()
	const setSearchCategory = useCommandKStore.use.setSearchCategory()

	return (
		<div className='flex flex-row gap-2 p-2'>
			{searchCategories.map((cat) => (
				<Badge
					key={cat}
					variant={searchCategory === cat ? 'default' : 'outline'}
					className='cursor-pointer'
					onClick={() => setSearchCategory(cat)}
				>
					<Trans i18nKey={`modules:${cat}_one`} />
				</Badge>
			))}
		</div>
	)
}

const useSearchData = () => {
	const searchCategory = useCommandKStore.use.searchCategory()
	const searchTerms = useCommandKStore.use.searchTerms()
	const debouncedSearchTerms = useDebounce(searchTerms, 500)

	const eventsFromRef = useEntriesList(
		!!debouncedSearchTerms && searchCategory === 'events'
			? 'events'
			: // biome-ignore lint/style/noNonNullAssertion: <explanation>
				undefined!,
		{ ref: debouncedSearchTerms, page_size: 10 },
	)

	const eventsFromEmail = useEntriesList(
		!!debouncedSearchTerms && searchCategory === 'events'
			? 'events'
			: // biome-ignore lint/style/noNonNullAssertion: <explanation>
				undefined!,
		{ email: debouncedSearchTerms, page_size: 10 },
	)

	const usersFromEmail = useEntriesList(
		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		!!debouncedSearchTerms && searchCategory === 'users' ? 'users' : undefined!,
		{ email: debouncedSearchTerms, page_size: 10 },
	)

	const accountsFromTitle = useEntriesList(
		!!debouncedSearchTerms && searchCategory === 'accounts'
			? 'accounts'
			: // biome-ignore lint/style/noNonNullAssertion: <explanation>
				undefined!,
		{ title: debouncedSearchTerms, page_size: 10 },
	)

	const isLoading =
		usersFromEmail.isLoading ||
		accountsFromTitle.isLoading ||
		eventsFromRef.isLoading ||
		eventsFromEmail.isLoading

	return {
		events: [
			...(eventsFromEmail.data?.data || []),
			...(eventsFromRef.data?.data || []),
		].reduce((acc, event) => {
			if (!acc.find((e: any) => e.id === event.id)) {
				acc.push(event)
			}

			return acc
		}, []),
		users: usersFromEmail.data?.data || [],
		accounts: accountsFromTitle.data?.data || [],
		isLoading,
	}
}

export const CommandMenu = () => {
	const { t } = useTranslation()
	const router = useRouter()
	const [open, setOpen] = useState(false)

	const searchTerms = useCommandKStore.use.searchTerms()
	const setSearchTerms = useCommandKStore.use.setSearchTerms()

	const { events, users, accounts } = useSearchData()

	useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault()
				setOpen((open) => !open)
			}
		}

		document.addEventListener('keydown', down)

		return () => document.removeEventListener('keydown', down)
	}, [])

	return (
		<CommandDialog
			open={open}
			commandProps={{ shouldFilter: false }}
			onOpenChange={setOpen}
		>
			<CommandInput
				value={searchTerms}
				onValueChange={setSearchTerms}
			/>

			<CategorySwitch />

			<Separator />

			<CommandList className='md:min-h-[400px] md:min-w-[500px]'>
				<CommandEmpty>{'Aucuns résultats.'}</CommandEmpty>

				{!!events.length && (
					<CommandGroup heading={t('modules:events_other')}>
						{events.map((event: any) => (
							<CommandItem
								key={event.id}
								onSelect={() => {
									router.push(`/events/${event.id}`)
									setOpen(false)
								}}
							>
								<DescribeIcon
									describeId='events'
									className='mr-2 size-4'
								/>

								<div>
									<div className='font-bold'>{event.title}</div>

									<div className='text-xs'>{event.owner_email}</div>
								</div>
							</CommandItem>
						))}
					</CommandGroup>
				)}

				{!!users.length && (
					<CommandGroup heading={t('modules:users_other')}>
						{users.map((user) => (
							<CommandItem
								key={user.id}
								onSelect={() => {
									router.push(`/users/${user.id}`)
									setOpen(false)
								}}
							>
								<DescribeIcon
									describeId='users'
									className='mr-2'
								/>

								<div>
									<div className='font-bold'>
										{user.first_name}

										{user.last_name}
									</div>

									<div className='text-xs'>{user.email}</div>
								</div>
							</CommandItem>
						))}
					</CommandGroup>
				)}

				{!!accounts.length && (
					<CommandGroup heading={t('modules:accounts_other')}>
						{accounts.map((account) => (
							<CommandItem
								key={account.id}
								onSelect={() => {
									router.push(`/accounts/${account.id}`)
									setOpen(false)
								}}
							>
								<DescribeIcon
									describeId='accounts'
									className='mr-2'
								/>

								<div>
									<div className='font-bold'>{account.title}</div>

									<div className='text-xs'>
										{t(`values:values.type.${account.type}`)}
									</div>
								</div>
							</CommandItem>
						))}
					</CommandGroup>
				)}
			</CommandList>
		</CommandDialog>
	)
}
