import { TooltipProvider } from '@cocoonspace/ui-admin/components/tooltip'
import {
	HydrationBoundary,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ThemeProvider } from 'next-themes'
import { NuqsAdapter } from 'nuqs/adapters/next/pages'
import type { PropsWithChildren } from 'react'

const queryClient = new QueryClient()

export const Providers = ({
	children,
	pageProps,
}: PropsWithChildren<{ pageProps: Record<string, unknown> }>) => (
	<NuqsAdapter>
		<ThemeProvider
			attribute='class'
			defaultTheme='light'
			enableSystem
			disableTransitionOnChange
		>
			<QueryClientProvider client={queryClient}>
				<HydrationBoundary state={pageProps.dehydratedState}>
					<TooltipProvider>{children}</TooltipProvider>
				</HydrationBoundary>

				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</ThemeProvider>
	</NuqsAdapter>
)
