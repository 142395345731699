// biome-ignore lint/style/useNodejsImportProtocol: <explanation>
const path = require('path')

const sharedNamespaces = ['common', 'errors', 'extras', 'promotions', 'auth']

module.exports = {
	i18n: {
		debug:
			process.env.NODE_ENV === 'development' && typeof window !== 'undefined',
		reloadOnPrerender: process.env.NODE_ENV === 'development',
		defaultLocale: 'fr',
		locales: ['fr'],
		localeExtension: 'json',
		localeDetection: false,
		serializeConfig: false,
		defaultNS: 'common',
		ns: [
			...sharedNamespaces,
			'account',
			'actions',
			'attributesLabels',
			'events',
			'linksLabels',
			'login',
			'modules',
			'orders',
			'planning',
			'quotations',
			'sectionsLabels',
			'values',
		],
		localePath: path.resolve('./public/locales/'),
	},
}
