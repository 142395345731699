import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@cocoonspace/ui-admin/components/dropdown-menu'
import { cn } from '@cocoonspace/ui-admin/lib/utils'
import { CircleUserIcon } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ActionIcon } from '~/components/action-icon'

export const UserMenu = ({
	className,
	dense,
}: {
	className?: string
	dense?: boolean
}) => {
	const router = useRouter()
	const { t } = useTranslation()
	const { logout, fireAuthUser } = useAuth()

	return (
		<DropdownMenu>
			<DropdownMenuTrigger
				className={cn('ml-auto flex flex-row items-center gap-4', className)}
			>
				{!dense && (
					<div className='flex-1 truncate text-right'>
						<div className='truncate text-sm'>{fireAuthUser?.displayName}</div>

						<div className='truncate text-gray-800 text-xxs'>
							{fireAuthUser?.email}
						</div>
					</div>
				)}

				{fireAuthUser?.photoURL ? (
					<img
						src={fireAuthUser?.photoURL}
						alt=''
						width={32}
						height={32}
						referrerPolicy='no-referrer'
						className='rounded-full'
					/>
				) : (
					<CircleUserIcon className='text-4xl' />
				)}
			</DropdownMenuTrigger>

			<DropdownMenuContent
				sideOffset={15}
				onCloseAutoFocus={(e) => e.preventDefault()}
			>
				<DropdownMenuItem onClick={() => logout().then(() => router.push('/'))}>
					<ActionIcon
						actionName='logout'
						className='mr-2 inline'
					/>

					{t('actions:logout.btnLabel')}
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}

export const AppNavbar = () => {
	const { isAuth } = useAuth()

	return (
		<nav
			className={cn(
				'fixed inset-x-0 top-0 z-40 h-[67px] w-full print:hidden',
				'bg-white shadow',
				'flex shrink-0 flex-row items-center px-6',
			)}
		>
			<Link
				href='/'
				className={cn(
					'mr-auto flex flex-row items-start gap-2 pb-1 font-bold text-4xl text-primary leading-none',
				)}
			>
				cocoon space
				{process.env.NEXT_PUBLIC_APP_ENV !== 'prod' && (
					<span className='pt-2 text-gray-700 text-xxs uppercase leading-none'>
						{process.env.NEXT_PUBLIC_APP_ENV}
					</span>
				)}
			</Link>

			{isAuth && <UserMenu />}
		</nav>
	)
}
