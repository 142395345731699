import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import { cn } from '@cocoonspace/ui-admin/lib/utils'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { type PropsWithChildren, useEffect } from 'react'
import { CommandMenu } from '~/components/command-menu'
import { PageLoader } from '~/components/page-loader'
import { useUserPrefsStore } from '~/stores/user-prefs.store'

const Sidebar = dynamic(
	() => import('~/components/sidebar').then((mod) => mod.Sidebar),
	{ ssr: false },
)

export const PrivateLayout = ({ children }: PropsWithChildren) => {
	const router = useRouter()
	const { isLoading, isAuth } = useAuth()

	const isSidebarVisible = useUserPrefsStore.use.isSidebarVisible()
	const accentColor = useUserPrefsStore.use.accentColor()

	useEffect(() => {
		if (!isLoading && !isAuth) {
			router.push('/login')
		}
	}, [isAuth, isLoading, router])

	if (isLoading) return <PageLoader />

	if (!isLoading && isAuth) {
		return (
			<div
				className={cn(
					'flex h-[100dvh] flex-row gap-2 bg-slate-200 p-2 dark:bg-slate-800',
					`accent-${accentColor}`,
				)}
			>
				{isSidebarVisible && <Sidebar />}

				<div
					key={router.route}
					className='flex flex-1 flex-col gap-2 overflow-y-auto overflow-x-hidden'
				>
					{children}
				</div>

				<CommandMenu />
			</div>
		)
	}

	return null
}
